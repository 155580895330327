import { Pipe, PipeTransform } from '@angular/core';
import { BaseUrlService } from '../services/base-url.service';

@Pipe({
    name: 'toImageUrl'
})
export class ToImageUrlPipe implements PipeTransform {
    status;
    constructor(private api: BaseUrlService) {}
    transform(value: string, imageType: string) {
        imageType === 'id' ? (imageType = '/images/ids/') : (imageType = '/images/');

        value = value
            ? value.includes('http://') || value.includes('https://')
                ? value
                : this.api.endpoint + 'uploads' + imageType + value
            : '';

        // check if image does exist
        // const img = new Image();
        // img.src = value;
        // img.onload = (res: any) => {
        //     this.status = res.type; // load
        // };
        // img.onerror = (res: any) => {
        //     this.status = res.type; // error
        // };

        // return this.status === 'load' ? value : '/assets/images/default-image-1.png';
        return value;
    }
}
