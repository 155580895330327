import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cutString'
})
export class CutStringPipe implements PipeTransform {
    transform(text?: string, start?: number, count?: number): string {
        if (text) {
            let result;
            let remain = text.length - start;
            if (start > -1 && count > -1) {
                result =
                    count > 0 && count < text.length ? text.substring(start, count) : text.substring(start, remain);
            } else if (start > -1 && !count) {
                result = start < text.length ? text.substring(start, remain) : '';
            } else {
                result = text;
            }
            return result;
        }
    }
}
