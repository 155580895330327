import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { IpAddressService } from './ip-address.service';

@Injectable({
    providedIn: 'root'
})
export class BaseUrlService {
    constructor(private ip: IpAddressService) {
        this.ip.getIPAddress().subscribe((res: any) => {
            localStorage.setItem('x-ip', res.ip);
        });
    }
    userIP = '';
    endpoint = environment.apiUrl;
    headers = new HttpHeaders({
        'Content-type': 'application/json',
        'x-ip': `${localStorage.getItem('x-ip') ? localStorage.getItem('x-ip') : localStorage.getItem('x-ip')}`,
        Authorization: localStorage.getItem('accessToken')
    });
}
