// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiUrl: 'http://daiku-apidev.yes.com.kh/',
    // apiUrl: 'https://daiku-api.yes.com.kh/',
    // apiUrl: 'http://localhost:3500/',
    firebase: {
        apiKey: 'AIzaSyClAxpgUcYv7uKgPQy7rvkFDa-zkO0ZyEk',
        authDomain: 'yes-daiku-5fe73.firebaseapp.com',
        databaseURL: 'https://yes-daiku-5fe73.firebaseio.com',
        projectId: 'yes-daiku-5fe73',
        storageBucket: 'yes-daiku-5fe73.appspot.com',
        messagingSenderId: '755589966023'
    }
};
