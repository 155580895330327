import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutStringPipe } from './cut-string.pipe';
import { ExcerptPipe } from './excerpt.pipe';
import { ToImageUrlPipe } from './to-image-url.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [CutStringPipe, ExcerptPipe, ToImageUrlPipe],
    exports: [CutStringPipe, ExcerptPipe, ToImageUrlPipe]
})
export class SharedPipesModule {}
